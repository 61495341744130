<template>
    <div class="infrastructure-header"  id="infrastructure">
        <h2>INFRASTRUCTURE</h2>
        <div class="accent-end"><img src="bg/barcode-accent.png" alt="Barcode Accent"></div>
    </div>
    <div class="infrastructure">
        <div class="production-row">
            <div class="top-pagination">
                <button @click="production = true"
                    :style="{ backgroundColor: production ? 'white' : 'transparent', color: production ? 'black' : 'white' }">PRODUCTION</button>
                <button @click="production = false"
                    :style="{ backgroundColor: !production ? 'white' : 'transparent', color: !production ? 'black' : 'white' }">RESEARCH</button>
            </div>
            <div class="production-para">
                <p v-if="production">The Roko Network is fully committed to a decentralized infrastructure as the
                    cornerstone of its vision for a self-organizing human-machine network. This decentralization ensures
                    every operational aspect—from governance to data processing—is democratically controlled by a diverse
                    community, safeguarding the system against central failures.</p>
                <p v-if="!production">ROKO's R&D teams focus on developing innovative systems in interactive robotics and
                    film industry tech, particularly in environment and actor capture and storage technologies.</p>
            </div>
        </div>

        <div class="central-component-wrapper">
            <div class="central-component" :style="centralComponentStyle">
                <template v-for="(page, index) in orderedPages" :key="page.title + index">
                    <div class="page" :class="{ 'first-item': index === 0 }">
                        <img :src="page.img" alt="">
                        <h2>{{ page.title }}</h2>
                        <p>{{ page.description }}</p>
                        <div class="accent">
                            <p v-if="page.prod">// PRODUCTION</p>
                            <p v-if="!page.prod">// RESEARCH</p>
                            <p>00{{ page.number }}</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- Central Pagination Controls -->
        <div class="central-pagination-controls">
            <button @click="changeCentralPage(-1)" :disabled="centralPage === 0">◀</button>
            <span class="pagebox">{{ centralPage + 1 }}</span>
            <button @click="changeCentralPage(1)" :disabled="centralPage === filteredPages.length - 1">▶</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            production: true,
            centralPage: 0,
            pages: [
                { number: "1", title: "BITTENSOR", description: "Bittensor is a transformative technology that enables distributed machine learning and AI applications on decentralized networks.", img: "/bg/bittensor.png", prod: true },
                { number: "2", title: "NVIDIA", description: "NVIDIA is a software and fabless company which designs and supplies graphics processing units (GPUs), APIs for data science and high-performance computing as well as system on a chip units (SoCs) for the mobile computing and automotive market.", img: "/bg/nvidia.png", prod: true },
                { number: "3", title: "TAO / CETI", description: "TAO/CETI is on a mission to accelerate the advancement of decentralized artificial intelligence, building globally distributed, high-performance, scalable AI infrastructure for developers and distributed networks.", img: "/bg/ceti.png", prod: true },
                { number: "4", title: "21E8", description: "21E8 offers a Computational Data Market.", img: "/bg/Group.png", prod: true },
                { number: "5", title: ".BOX", description: ".box is the first of its kind: one domain fully integrated with two technologies (Internet and Blockchain). .box domains work seamlessly with both the Domain Name System (DNS) and the Ethereum Name Service (ENS).", img: "/bg/box.svg", prod: true },
                { number: "6", title: "Ethereum", description: "The ROKO network is built on Ethereum, the leading blockchain platform, renowned for its robust smart contract capabilities and dynamic developer community. ", img: "/bg/ethereum.png", prod: true },
                { number: "1", title: "Synternet", description: "Synternet provides permissionless real-time data infrastructure, empowering developers to build the next generation of cross-chain dApps through utilizing composable data streams.", img: "/bg/syn.png", prod: false },
                { number: "2", title: "Marlin", description: "Marlin is a verifiable computing protocol featuring TEE and ZK-based coprocessors to delegate complex workloads over a decentralized cloud, allowing reliable and secure renting of compute instances or deployment of serverless functions over a decentralized pool of nodes.", img: "/bg/marlin.png", prod: false },
                { number: "3", title: "Celestia", description: "Celestia is a modular data availability network that makes it easy for anyone to securely launch their own blockchain.", img: "/bg/celestia.png", prod: false }
            ]
        };
    },
    computed: {
        filteredPages() {
            return this.pages.filter(page => page.prod === this.production);
        },
        orderedPages() {
            let start = this.centralPage;
            return [...this.filteredPages.slice(start), ...this.filteredPages.slice(0, start)];
        },
        centralComponentStyle() {
            return { transform: `translateX(0%)` };
        }
    },
    methods: {
        changeCentralPage(step) {
            let newPage = this.centralPage + step;
            newPage = (newPage + this.filteredPages.length) % this.filteredPages.length;
            this.centralPage = newPage;
        }
    }
};
</script>

  
<style scoped lang="scss">
// ... Keep your existing styles ...
.central-component-wrapper {
    margin-top: 5rem;
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
}

.accent {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: auto;
}


.production-row {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    padding: 2rem;
    gap: 2rem;

    .production-para {
        width: 1000px;
        margin: auto;
        max-width: 100%;

        p {
            text-transform: uppercase;
        }
    }
}

.top-pagination {
    margin: auto;
    background: black;
    width: fit-content;
    display: flex;
    flex-flow: row;
    padding: 0.4rem;
    gap: 0.5rem;
    border-radius: 0.25rem;
    clip-path: polygon(0.65rem 0%, 100% 0, 100% calc(100% - 0.65rem), calc(100% - 0.65rem) 100%, 0 100%, 0 0.65rem);

    button {
        cursor: pointer;
        border: unset;
        width: 120px;
        padding: 0.5rem 1rem;
        font-family: "Rajdhani", sans-serif;
        background: black;
        color: white;
        border-radius: 0.125rem;
        clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1.5;
        font-size: 1rem;
        text-align: center;
        font-weight: 600;
        transition: all 0.2s ease;
        /* Space between text and icon */
        /* Add other button styles */
        border: unset;
        font-family: "Rajdhani", sans-serif;
        letter-spacing: 1px;
    }
}

.central-component {
    display: flex;
    width: calc(100% - 8rem);
    height: auto;
    margin: auto;
    padding: 2rem;
    background-image: url(/bg/subitems.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-size: 100%;
    transition: transform 0.3s ease;
    gap: 2rem;
    flex-flow: row;

    .page {
        animation-duration: 0s;

        * {
            animation-duration: 0s;
        }
    }

    .first-page {
        animation-duration: 0.2s !important;

        * {
            animation-duration: 0.2s !important;
        }
    }

}

.page {
    width: 15%;
    flex-shrink: 0; // Prevent shrinking
    position: relative;
    height: min-content;

    height: min-content;
    margin: auto 0px;

    &::after {
        position: absolute;
        left: -3rem;
        width: 1px;
        top: 0rem;
        height: 100%;
        background: #c1c1c1;
        content: '';
    }

    img {
        height: 24px;
        padding: 4px 0px;
    }

    h2 {
        margin: 0.2rem 0rem;
        font-size: 1.5rem;
    }

    .accent {
        display: flex;

        p {
            color: white;
            display: block;
            font-size: 0.5rem;
            width: 5rem
        }
    }

    h2,
    p {
        font-weight: 500;
        color: white;
    }

    p {
        display: none;
        margin-bottom: unset;
        margin-top: 0.5rem;
    }
}

.first-item+.page {
    &::after {
        display: none !important;
    }
}

.first-item {

    img {
        position: absolute;
        right: 3rem;
        top: 3rem;
        height: 32px;
    }

    .accent {
        display: flex;

        p {
            color: black;
            width: auto;
            display: flex;
            font-size: .75rem;

        }
    }

    h2,
    p {
        max-width: 400px;
        color: black;
        margin: unset;
        line-height: 1;
        width: calc(100% - 4rem);
        max-height: 120px;
        overflow: auto;
    }

    p {
        display: block;
    }

    h2 {
        font-weight: 500;
        margin-bottom: 1rem;
        font-size: 2rem;
    }


    &::after {
        display: none !important;
    }

    height: 200px;
    bottom: 0rem;
    min-width:510px;
    padding: 4rem;
    background-image: url(/bg/mainitem.png);
    background-repeat: no-repeat;
    background-size: auto;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin: 0px;
    z-index: 2;
    display: flex;
    flex-flow:column;
    justify-content: space-between;
}

.central-pagination-controls {
    // Styles for central pagination controls
    position: absolute;
    right: 4rem;
    display: flex;
    flex-flow: row;
    gap: 0.5rem;
    margin-top: 4rem;

    .pagebox {
        line-height: 32px;
        height: 32px;
        margin-top: -8px;
        display: flex;
        flex-flow: row;
        justify-content: center;
        border: 1px solid black;
        padding: 0.5rem;

        p {
            margin: auto;
        }
    }

    button {
        cursor: pointer;
        border: unset;
        background: black;
        width: 32px;
        height: 32px;
        color: white;
        clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
    }
}

.tab-switch {

    // Styles for tab switch buttons
    button.active {
        // Active tab styling
    }
}

.infrastructure-header {
    border-bottom: 1px solid #C1C1C1;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0rem 2rem;
    padding-top: 5rem;

    h2 {
        font-size: 70px;
        font-weight: 400;
        margin: unset;
    }

    .key-features-wrap {
        display: flex;
        flex-flow: column;
        padding: 2rem;
        justify-content: flex-end;

        .button-key {
            cursor: pointer;
            border: unset;
            width: 120px;
            padding: 0.5rem 1rem;
            font-family: "Rajdhani", sans-serif;
            background: black;
            color: white;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            font-weight: 600;
            gap: 10px;
            transition: all 0.2s ease;

            /* Space between text and icon */
            /* Add other button styles */
            &:hover {
                filter: invert(0.9);
            }

            p {
                margin: unset;
            }
        }
    }

    .accent-end {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 2rem;
    }
}


@media (max-width: 1200px) {
    .production-row {
        flex-flow: column;
    }

    .central-component {
        background-image: unset;
    }

    .infrastructure-header {
        border-bottom: 1px solid #C1C1C1;
        display: flex;
        flex-flow: row;
        margin-top: 5rem;
        justify-content: space-between;
        padding: 0rem 2rem;

        h2 {
            font-size: 10vw;
            font-weight: 400;
            margin: unset;
        }
    }

    .first-item {

        img {
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
            height: 24px;
        }

        h2,
        p {
            max-width: auto;
            color: black;
            margin: unset;
            line-height: 1;
            width: 100%;
            max-height: 100%;
            height: auto;
            overflow: visible;
        }

        p {
            display: block;
            font-size: 0.75rem;

        }

        h2 {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }


        &::after {
            display: none !important;
        }

        height: 50vw;
        bottom: 0rem;
        min-width: calc(100% - 4rem);
        margin: 0px;

        margin-left:-2rem;
        width: calc(100%);
        padding: 2rem;
        background-image: url(/bg/Subtract.png);
        background-repeat: no-repeat;
        background-size: 100% 180%;

        z-index: 2;
    }

    .central-pagination-controls {
        // Styles for central pagination controls
        position: absolute;
        right: 4rem;
        display: flex;
        flex-flow: row;
        gap: 0.5rem;
        margin-top: 2rem;

        .pagebox {
            line-height: 32px;
            height: 32px;
            margin-top: -8px;
            display: flex;
            flex-flow: row;
            justify-content: center;
            border: 1px solid black;
            padding: 0.5rem;

            p {
                margin: auto;
            }
        }

        button {
            cursor: pointer;
            border: unset;
            background: black;
            width: 32px;
            height: 32px;
            color: white;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
        }
    }

    .accent-end {
        display: none !important;
    }

}</style>
  
